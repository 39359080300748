import request from './ajax'
// 资金路由模块
const moneyUrl = '/api/money/'
// 获取资金统计信息
export const getMoneyStatistics = (data) => request(moneyUrl + 'statistics', 'post', { ...data })
// 获取资金类型
export const getWaterType = (data) => request(moneyUrl + 'water_type', 'post', { ...data })
// 获取资金流水
export const getWaterList = (data) => request(moneyUrl + 'water_list', 'post', { ...data })
export const getShopMoneyStatistics = (data) => request(moneyUrl + 'shop_statistics', 'post', { ...data })
export const getShopMoneyList = (data) => request(moneyUrl + 'shop_list', 'post', { ...data })
export const getShopWaterType = (data) => request(moneyUrl + 'shop_type', 'post', { ...data })
