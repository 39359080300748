<template>
  <div>
    <div class="big-box-layout">
      <NavBar :iconColor="'#fff'">
        <div slot="title">我的公益豆</div>
      </NavBar>
      <!-- 余额明细 -->
      <div class="remaining-sum-layout">
        <div class="view-class">{{ shopName }}</div>
        <div class="remaining-sum">
          <div class="money-detail">
            <div class="available">可用公益豆</div>
            <div>{{ twoNum(moneyInfo.money) }}</div>
          </div>
          <div class="money-clos-spend">
            <div class="date-of" @click="dateButton">
              <span class="year-month">{{ dateStr }}</span>
              <!-- 下箭头 -->
              <van-icon name="arrow-down" v-if="true" size="17" />
              <!-- 上箭头 -->
              <van-icon name="arrow-up" v-else />
            </div>
            <div class="money-sum">
              <span class="income-money">
                收入：{{ twoNum(moneyInfo.income) }}
              </span>
              <span class="spend-money">
                支出：{{ twoNum(moneyInfo.expenses) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- 日期选择弹框 -->
      <van-popup
        v-model="showDate"
        round
        position="bottom"
        :style="{ height: '50%' }"
      >
        <div class="popup-layout">
          <van-datetime-picker
            v-model="currentDate"
            ref="picker"
            type="year-month"
            visible-item-count="5"
            :min-date="minDate"
            :max-date="maxDate"
            :formatter="formatter"
          >
            <template #cancel>
              <div></div>
            </template>
            <template #confirm>
              <div></div>
            </template>
          </van-datetime-picker>
        </div>
        <div class="button-operation">
          <van-button type="danger" round @click="confirmBtn">确认</van-button>
          <van-button type="danger" round plain @click="cancelBtn">
            取消
          </van-button>
        </div>
      </van-popup>
      <!-- 佣金 -->
      <div class="detail-padding" v-if="true">
        <div class="detail-class-layout">
          <div
            class="detail-class-text"
            :class="{ 'detail-class-dynamic': active == item.water_key }"
            v-for="(item, index) in classList"
            :key="index"
            @click="onClass(item.water_key)"
          >
            <span>{{ item.water_name }} </span>
          </div>
        </div>
        <div class="detail-card">
          <van-list
            v-model="loading"
            :finished="finished"
            offset="20"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-cell v-for="(item, i_index) in list" :key="i_index">
              <div class="list-layout">
                <div class="list-left">
                  <div>{{ item.status_str }}</div>
                  <div class="add-money" v-if="item.price * 1 !== 0">
                    {{ item.add_reduce * 1 > 0 ? "+" : "-" }}{{ item.price }}
                  </div>
                </div>
                <div class="list-right">
                  <div>{{ item.created_at }}</div>
                  <!-- 根据分类栏的不同决定是否显示 -->
                  <!-- <div v-if="true">公益豆余额 {{ item.remainder }}</div> -->
                </div>
              </div>
            </van-cell>
          </van-list>
        </div>
      </div>
      <div class="detail-padding" v-else>
        <div class="no-record">暂无记录</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar.vue";
import { getMoneyDetail } from "@/api/index.js";
import { getMoneyStatistics, getWaterType } from "@/api/money";
export default {
  name: "beans-consume",
  components: {
    NavBar,
  },
  data() {
    return {
      moneyType: 3,
      shopId:
        this.$route.query.shopId === undefined ? -1 : this.$route.query.shopId,
      shopName:
        this.$route.query.shopName === undefined
          ? ""
          : this.$route.query.shopName,
      active: 0, // 分类样式显示
      showDate: false,
      minDate: new Date(2015, 0, 1),
      maxDate: new Date(2025, 11, 1),
      currentDate: new Date(),
      classList: ["全部"],
      finished: false, // 是否加载完成
      loading: false, // 是否开启加载
      list: [],

      dateStr: "",
      page: 1,
      moneyInfo: {
        expenses: 0,
        income: 0,
        money: 0,
      },
      isAjax: false,
    };
  },
  mounted() {
    this.getMoneyStatistics();
    this.getWaterType();
  },
  methods: {
    goTuUrl(type) {
      switch (type) {
        case 1:
          this.$router.push({
            name: "remain-cash",
            query: { money: this.moneyInfo.money },
          });
          break;

        default:
      }
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    async onLoad() {
      this.getWaterList();
    },
    dateButton() {
      this.showDate = true;
    },
    async getMoneyStatistics() {
      const res = await getMoneyStatistics({
        money_type: this.moneyType,
        date: this.dateStr,
        shop_id: this.shopId,
      });
      if (res.code * 1 == 1) {
        this.moneyInfo = res.data;
      }
    },
    async getWaterType() {
      const res = await getWaterType({
        money_type: this.moneyType,
        date: this.dateStr,
        page: this.page,
      });
      if (res.code * 1 == 1) {
        this.classList = res.data;
      }
    },
    async getWaterList() {
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      const res = await getMoneyDetail({
        money_type: this.moneyType,
        water_key: this.active,
        date: this.dateStr,
        page: this.page,
        shop_id: this.shopId,
      });
      console.log("列表=====================", res);
      this.isAjax = false;
      this.list = [...this.list, ...res.data.items];
      ++this.page;
      this.loading = false;
      if (this.list.length >= res.data.total_num * 1) {
        this.finished = true;
      }
    },
    // 确认按钮
    confirmBtn() {
      //拿到选中的年月
      this.page = 1;
      this.list = [];
      let dateStr = this.$refs.picker.getPicker().getValues().join("-");
      dateStr = dateStr.replace("年", "");
      dateStr = dateStr.replace("月", "");
      this.dateStr = dateStr;
      this.showDate = false;
      this.getWaterList();
    },
    cancelBtn() {
      this.showDate = false;
    },
    remainBtn(num) {
      // 余额
      this.moneyType = num;
      this.getMoneyStatistics();
      this.getWaterType();
      this.onClass(0);
    },

    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    onClass(index) {
      this.active = index;
      this.page = 1;
      this.list.splice(0, this.list.length);
      this.finished = false;
      this.getWaterList();
    },
  },
  created() {
    let date = new Date();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    this.dateStr = date.getFullYear() + "-" + month;
  },
};
</script>

<style lang="less" scoped>
.big-box-layout {
  height: 100vh;
  width: 100vw;
  background: url("~@/assets/my/my-page-background@2x.png") no-repeat center
    top/100%;
  .nav-layout {
    color: #fff;
  }
}
.remaining-sum-layout {
  padding: 48px 12px 0 12px;
}
.view-class {
  font-size: 16px;
  text-align: center;
  color: #fff;
  padding: 26px;
}
.remaining-sum {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 12px;
  border-radius: 16px;
  background-color: #fff;
  .money-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 30px;
    font-weight: 600;
    padding: 20px 0;
    border-bottom: 1px solid rgb(238, 238, 238);
    .available {
      font-size: 18px;
      color: #6f6f6f;
      font-weight: normal;
    }
  }
  .money-clos-spend {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0 10px 0;
    .date-of {
      display: flex;
      align-items: center;
      .year-month {
        font-size: 17px;
        margin-right: 10px;
      }
    }
    .money-sum {
      color: #fd6d1f;
      font-size: 16px;
      .income-money {
        margin-right: 10px;
      }
    }
  }
}
// 弹框样式
.popup-layout {
  padding: 12px;
}
.button-operation {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 20px 66px;
  box-sizing: border-box;
}
.van-button {
  width: 90px;
  height: 40px;
}
// 价格明样式
.detail-padding {
  padding: 0 12px 12px 12px;
  .no-record {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    height: 334px;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 12px;
  }
}
.detail-class-layout {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .detail-class-text {
    text-align: center;
    padding: 6px 0;
    width: 90px;
    font-size: 16px;
    color: #6f6f6f;
  }
  .detail-class-dynamic {
    // padding: 10px 0;
    width: 90px;
    text-align: center;
    border-radius: 999px;
    background-color: #ed301d;
    color: #fff;
    transition: all 0.3s;
  }
}
// 列表
.detail-card {
  padding: 12px;
  background-color: #fff;
  border-radius: 16px;
}
.van-cell {
  padding: 12px;
}
.list-layout {
  .list-left {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    .add-money {
      color: #fd6d1f;
    }
  }
  .list-right {
    display: flex;
    justify-content: space-between;
    color: #aaaaaa;
    font-size: 13px;
  }
}
</style>
