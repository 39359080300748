import request from './ajax'
const usedUrl = '/api/member/'
// 注册信息
export const getSignInInfo = (data) => request('/api/member/register', 'post', { ...data })
// 登陆
export const login = (data) => request('/api/member/login', 'post', { ...data })
// 会员信息
export const getUserInfo = (data) => request('/api/member/info', 'post', { ...data })
// 秒杀商品列表
export const getSeckillList = (data) => request('/api/seckill/goods', 'post', { ...data })
// 秒杀商品列表
export const setSynopsis = (data) => request(usedUrl + 'describe', 'post', { ...data })
export const getMoneyDetail = (data) => request('/api/money/water_list', 'post', { ...data })

// 腾讯地址搜索
export const txSuggestion = (data) => request('/api/txMap/ws/place/v1/suggestion', 'get', data)
//百度坐标转换腾讯坐标
export const bdToTx = (data) => request('/api/txMap/ws/coord/v1/translate', 'get', data)
export const txToBd = (data) => request('/api/bdMap/geoconv/v1/', 'get', data)
export const txToGd = (data) => request('/api/bdMap/geoconv/v1/', 'get', data)
