<template>
  <div class="nav-layout">
    <van-icon
      :name="iconName"
      @click="navCallback"
      size="20"
      :color="iconColor"
    />
    <slot name="title"></slot>
    <van-popover
      v-model="showPopover"
      :actions="actions"
      placement="bottom-end"
      theme="dark"
      @select="onSelect"
    >
      <template #reference>
        <van-icon
          name="ellipsis"
          @touchend="ellipsis"
          size="20"
          :color="iconColor"
          class="icon-font"
        />
      </template>
    </van-popover>
  </div>
</template>

<script>
export default {
  name: "nav-bar",
  props: {
    bool: {
      default: false,
    },
    iconColor: {
      default: "#9B9B9B",
    },
    iconName: {
      default: "arrow-left",
    },
  },
  data() {
    return {
      showPopover: false,
      actions: [
        { text: "返回首页", icon: "wap-home-o" },
        { text: "购物车", icon: "cart-o" },
        { text: "分类", icon: "cluster-o" },
        { text: "我的", icon: "friends-o" },
      ],
    };
  },
  methods: {
    navCallback() {
      if (this.bool) {
        this.$emit("navCallback");
      } else {
        this.$router.go(-1);
      }
    },
    ellipsis() {
      //  操作选项框
      this.showPopover = !this.showPopover;
    },
    onSelect(action, index) {
      if (index === 0) {
        this.$router.push("/");
      } else if (index === 1) {
        this.$router.push("/shop-cart");
      } else if (index === 2) {
        this.$router.push("/classify");
      } else if (index === 3) {
        this.$router.push("/my");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 200;
  font-size: 18px;
  padding: 14px 12px;
}
.van-popover__wrapper {
  display: flex;
  align-items: center;
}
.icon-font {
  font-weight: 600;
}
</style>